<template>
    <div>
        <el-card>
            <div class="global-search-box">
                <div class="search-content">
                    <div class="search-item">
                        <label class="search-label">消息类型：</label>
                        <div>
                            <el-select v-model="messageTypeActive" filterable clearable placeholder="请选择">
                                <el-option v-for="(item, index) in messageType" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="search-item">
                        <label class="search-label">消息来源：</label>
                        <el-input type="text" v-model="information" placeholder="请输入关键词查找" />
                    </div>
                    <div class="search-item">
                        <label class="search-label">消息内容：</label>
                        <el-input type="text" v-model="messageContent" placeholder="请输入关键词查找" />
                    </div>
                    <div class="search-item">
                        <label class="search-label">通知时间：</label>
                        <div class="search-time-between">
                            <el-date-picker ref="noticePickRef" v-model="noticeTime" type="datetimerange"
                                :picker-options="pickerOptionLater" start-placeholder="开始日期" format="yyyy-MM-dd"
                                end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="serach-buttom-button">
                    <el-button type="primary" @click="handleSumbited">搜索</el-button>
                    <el-button @click="resetting()">重置条件</el-button>
                </div>
            </div>
        </el-card>
        <el-card>
            <div class="notice-list-box">
                <div class="notice-thead">
                    <div class="tabs">
                        <el-tabs v-model="tabsActive" @tab-click="handleTheadTab">
                            <el-tab-pane label="全部" name="0"></el-tab-pane>
                            <el-tab-pane label="未读消息" name="1"></el-tab-pane>
                            <el-tab-pane label="已读消息" name="2"></el-tab-pane>
                        </el-tabs>
                    </div>
                    <div class="btns">
                        <span @click="handleAllRead">清除未读</span>
                    </div>
                </div>
                <div class="notice-table">
                    <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName">
                        <el-table-column prop="content" label="内容" width="600">
                            <template slot-scope="scope">
                                <div v-html="scope.row.content"></div>
                            </template>
                        </el-table-column>
                        <el-table-column label="类型">
                            <template slot-scope="scope">
                                {{ messageType.filter(item => item.value == scope.row.type)[0]['label'] }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="from_name" label="来源"></el-table-column>
                        <el-table-column prop="created_at" label="时间" width="200"></el-table-column>
                        <el-table-column label="操作" align="right">
                            <template slot-scope="scope" v-if="scope.row.type != 0">
                                <span class="show-more" @click="showDetail(scope.row)"
                                    v-if="hasPerm(['system.message'])">查看</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="pagination">
                <el-pagination @size-change="handleSizeChange" :hide-on-single-page="false"
                    @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 40]"
                    :page-size="eachPage" layout="total, sizes, prev, pager, next, jumper" :pager-count="5"
                    :total="total_entry">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            messageTypeActive: '',
            messageType: [{
                value: '0',
                label: '系统消息'
            }, {
                value: '1',
                label: '售后订单提醒'
            }, {
                value: '2',
                label: '商品库存提醒'
            }, {
                value: '3',
                label: '下单提醒'
            }],
            information: '',
            messageContent: '',
            noticeTime: '',
            // 设定时间选择区间截止为当天
            pickerOptionLater: {
                disabledDate(time) {
                    var date = new Date(new Date().setHours(23, 59, 59, 59)).getTime()
                    return time.getTime() > date
                },
                // 所有的截止时间为当天的23.59.59
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const start = new Date().setHours(0, 0, 0, 0);
                        const end = new Date().setHours(23, 59, 59, 59);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '7天',
                    onClick(picker) {
                        const end = new Date().setHours(23, 59, 59, 59);
                        const start = new Date();
                        start.setTime(end - (3600 * 1000 * 24 * 7 - 1000));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '30天',
                    onClick(picker) {
                        const end = new Date().setHours(23, 59, 59, 59);
                        const start = new Date();
                        start.setTime(end - (3600 * 1000 * 24 * 30 - 1000));
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            // 时间快捷选择
            timeLastLabel: ['今天', '昨天', '7天', '30天'],
            // 时间快捷选择标识
            timeLastIndex: -1,
            currentPage: 1,
            eachPage: 10,
            total_entry: 0,
            // 表头状态选中
            tabsActive: 0,
            tableData: []
        }
    },
    created() {
        this.handleSumbit();
    },
    methods: {
        tableRowClassName({ row }) {
            if (row.is_read === 1) {
                return 'yes-read';
            }
            return '';
        },
        handleTheadTab(tab) {
            this.tabsActive = tab.index;
            this.handleSumbit()
        },
        handleSumbit() {
            let data = {
                search: [],
                order: [{
                    "key": "id",
                    "value": "desc"
                }],
                page: this.currentPage,
                limit: this.eachPage
            };

            // 整理搜索数据
            if (this.messageTypeActive) {
                const _data = {
                    "key": "type",
                    "value": this.messageTypeActive,
                    "op": "equal"
                }
                data.search.push(_data)
            }
            if (this.information) {
                let _data = {
                    "key": "from_name",
                    "value": this.information,
                    "op": "search"
                }
                data.search.push(_data)
            }
            if (this.messageContent) {
                let _data = {
                    "key": "content",
                    "value": this.messageContent,
                    "op": "search"
                }
                data.search.push(_data)
            }
            if (this.noticeTime) {
                let _data = {
                    "key": "created_at",
                    "value": [this.common.timeStampTurnTime(this.noticeTime[0]), this.common.timeStampTurnTime(this.noticeTime[1])],
                    "op": "between"
                }
                data.search.push(_data)
            }
            if (this.tabsActive > 0) {
                let _data = {
                    "key": "is_read",
                    "value": this.tabsActive - 1,
                    "op": "equal"
                }
                data.search.push(_data)
            }

            this.$get(this.$apis.noticeList, data).then(res => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.total_entry = res.data.total;
                } else {
                    this.common.message(this, res.message);
                }
            }).catch(err => {
                // console.log(err)
                this.common.message(this, err.message);
            });
        },
        resetting() {
            this.messageTypeActive = '';
            this.information = '';
            this.messageContent = '';
            this.noticeTime = '';
            this.tabsActive = '0';
            this.handleSumbit()
        },
        handleAllRead() {
            this.$put(this.$apis.allReadmessage).then(res => {
                console.log(res, 'res');
                if (res.code == 200) {
                    this.$message.success('清除成功！')
                } else {
                    this.$message.error('清除失败！')
                }
                this.handleSumbit()
            })
        },
        handleSizeChange(val) {
            this.eachPage = val;
            this.handleSumbit();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.handleSumbit();
        },
		handleSumbited(){
			this.currentPage = 1;
			this.handleSumbit();
		},
        showDetail(data) {
            console.log(data, 'data');
            // 系统消息不用调，售后 下单 跳订单，商品库存 跳商品
            const { id, ref_id, type } = data;
            this.$put(`/message/${id}/read_message`).then(res => {
                // console.log(res, 'resss');
                if (res.code == 200 && res.data.result) {
                    // 处理成功，跳转路由
                    if (type == 2) {
                        this.$router.push({
                            name: "goods_add",
                            params: {
                                id: ref_id
                            }
                        });
                    } else if (type == 1) {
                        this.$router.push({
                            path: '/order/order_servicedetails',
                            query: {
                                id: ref_id
                            }
                        });
                    } else {
                        this.$router.push({
                            path: '/order/order_details',
                            query: {
                                id: ref_id
                            }
                        });
                    }
                }
            })
        },
        dataTimePickerChange(event) {
            if (event === null) {
                // 清除时间快捷选择标识
                this.timeLastIndex = -1
            }
        },
        handleLastTime(e) {
            if (this.timeLastIndex == e) return false
            this.timeLastIndex = e
            let timeBetween, startTime, lastTime;
            const _start = new Date(new Date().toLocaleDateString());
            switch (e) {
                case 0:
                    startTime = new Date(new Date().setHours(0, 0, 0, 0));
                    lastTime = new Date(new Date().setHours(23, 59, 59, 59));
                    timeBetween = [startTime, lastTime]
                    break;
                case 1:
                    startTime = _start.setTime(_start.getTime() - 3600 * 1000 * 24 * 1);
                    lastTime = new Date(new Date(startTime).setHours(23, 59, 59, 59));
                    timeBetween = [new Date(startTime), lastTime]
                    break;
                case 2:
                    startTime = _start.setTime(_start.getTime() - 3600 * 1000 * 24 * 7);
                    lastTime = new Date(new Date().setHours(23, 59, 59, 59));
                    timeBetween = [new Date(startTime), lastTime]
                    break;
                case 3:
                    startTime = _start.setTime(_start.getTime() - 3600 * 1000 * 24 * 30);
                    lastTime = new Date(new Date().setHours(23, 59, 59, 59));
                    timeBetween = [new Date(startTime), lastTime]
                    break;
            }
            this.noticeTime = timeBetween;

        }
    }
}
</script>

<style lang="scss" scoped>
.notice-list-box {
    .notice-thead {
        width: 100%;
        padding: 0 10px;
        border-bottom: 1px solid #f2f2f2;
        box-sizing: border-box;
        height: 50px;
        display: flex;
        // align-items: center;
        justify-content: space-between;

        .btns {
            height: 100%;
            display: flex;
            align-items: center;

            span {
                border: 1px solid #ededed;
                border-radius: 4px;
                padding: 4px 8px;
                color: #666;
                cursor: pointer;
                transition: all .2s;

                &:hover,
                &:focus {
                    border-color: var(--fontColor);
                    color: var(--fontColor);
                }
            }
        }

        .tabs {
            /deep/ .el-tabs__nav-wrap::after {
                background: #fff;
            }

            /deep/ .el-tabs__item {
                height: 49px;
                line-height: 49px;
            }
        }
    }

    .show-more {
        cursor: pointer;
    }

    .show-more,
    .notice-content {
        color: var(--fontColor);
        font-size: 14px;
    }

    /deep/ .el-table .yes-read .el-table__cell {

        color: #999999 !important;

    }

    /deep/ .el-table .yes-read .el-table__cell .notice-content {

        color: #999999 !important;

    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

/deep/ .el-table .el-table__header tr {
    background-color: #f9f9fb;
}

/deep/ .el-table .el-table__header th.el-table__cell {
    background-color: #f9f9fb;
}
</style>